import { IQueryNode } from './QueryNodeTypes';
import { IQueryNodeParser, IRegisterableQueryNodeParser } from './QueryNodeParser';
import { Logger } from 'coveo-search-ui';

export class QueryNodeTreeParser implements IQueryNodeTreeParser {
    private parsers: { [key: string]: (node: IQueryNode) => string } = {};

    constructor(private logger: Logger) {}

    registerParser<T extends IQueryNode>(queryNodeParser: IRegisterableQueryNodeParser<T>): void {
        this.parsers[queryNodeParser.type] = (node: IQueryNode) => queryNodeParser.parse(node as T);
    }

    parse(node: IQueryNode): string {
        try {
            return this.parsers[node.type](node);
        } catch (exception) {
            this.logger.error('Error when parsing a query node:', node, exception);
            return '';
        }
    }
}

export interface IQueryNodeTreeParser extends IQueryNodeParser {
    registerParser<T extends IQueryNode>(queryNodeParser: IRegisterableQueryNodeParser<T>): void;
}
