import { IRegisterableQueryNodeParser } from '../QueryNodeParser';
import { ITrueNode } from '../QueryNodeTypes';

export class TrueNodeParser implements IRegisterableQueryNodeParser<ITrueNode> {
    type: string = 'true';

    parse(node: ITrueNode): string {
        return '@uri';
    }
}
