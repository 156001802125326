import { IRegisterableQueryNodeParser, IQueryNodeParser } from '../QueryNodeParser';
import { INotNode } from '../QueryNodeTypes';

export class NotNodeParser implements IRegisterableQueryNodeParser<INotNode> {
    type: string = 'not';

    constructor(private nodeParser: IQueryNodeParser) {}

    parse(node: INotNode): string {
        return `NOT ${this.nodeParser.parse(node.operand)}`;
    }
}
