import {
    Component,
    ComponentOptions,
    IComponentBindings,
    Initialization,
    IBuildingQueryEventArgs,
    QueryEvents,
    ExpressionBuilder,
} from 'coveo-search-ui';

import { ICoveoFieldHandler } from '../../handlers/CoveoFieldsHandler';

import { Context } from '../../base/Context';

export interface ICoveoForSitecoreSyntaxOptions {}

export class CoveoForSitecoreSyntax extends Component {
    static ID = 'ForSitecoreSyntax';

    static options: ICoveoForSitecoreSyntaxOptions = {};

    private static readonly IS_COVEO_FOR_SITECORE_SYNTAX_EVENT_REGISTERED = 'coveoForSitecoreSyntaxEventRegistered';

    private static readonly SITECORE_FIELD_NAME_REGEX = /@[\w\_]*(?=\=|\=\=|\<\>|\s|$)/gi;

    private fieldHandler: ICoveoFieldHandler;

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreSyntaxOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreSyntax.ID, bindings);

        this.fieldHandler = Context.fields;

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreSyntax, options);

        if (!this.root.dataset[CoveoForSitecoreSyntax.IS_COVEO_FOR_SITECORE_SYNTAX_EVENT_REGISTERED]) {
            this.root.dataset[CoveoForSitecoreSyntax.IS_COVEO_FOR_SITECORE_SYNTAX_EVENT_REGISTERED] = 'true';
            this.bind.onRootElement(QueryEvents.doneBuildingQuery, this.onDoneBuildingQuery);
        }
    }

    public setFieldHandler(fieldHandler: ICoveoFieldHandler): void {
        this.fieldHandler = fieldHandler;
    }

    private onDoneBuildingQuery(buildingQueryArgs: IBuildingQueryEventArgs): void {
        this.replaceSitecoreFieldExpressionsByCoveoFieldExpressions(buildingQueryArgs.queryBuilder.expression);
    }

    private replaceSitecoreFieldExpressionsByCoveoFieldExpressions(expressionBuilder: ExpressionBuilder): void {
        const expressionParts = expressionBuilder.getParts();
        expressionParts.forEach((expression) => {
            const originalExpression = expression;

            expression = this.replaceSitecoreFieldNamesByCoveoFieldNames(expression);

            expressionBuilder.remove(originalExpression);
            expressionBuilder.add(expression);
        });
    }

    private replaceSitecoreFieldNamesByCoveoFieldNames(expression: string): string {
        const sitecoreFieldNamesMatched = this.extractSitecoreFieldNames(expression);
        if (sitecoreFieldNamesMatched) {
            sitecoreFieldNamesMatched.forEach((sitecoreFieldName) => {
                const coveoFieldName = this.convertUnaryFieldExpressionToCoveoUnaryFieldExpression(sitecoreFieldName);
                expression = expression.replace(sitecoreFieldName, coveoFieldName);
            });
        }
        return expression;
    }

    private extractSitecoreFieldNames(expression: string): string[] {
        return expression.match(CoveoForSitecoreSyntax.SITECORE_FIELD_NAME_REGEX);
    }

    private convertUnaryFieldExpressionToCoveoUnaryFieldExpression(unaryFieldExpression: string): string {
        if (!this.fieldHandler.isCoveoFieldName(unaryFieldExpression)) {
            return this.fieldHandler.toCoveo(unaryFieldExpression);
        } else {
            return unaryFieldExpression;
        }
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreSyntax);
