import {
    Component,
    ComponentOptions,
    IComponentBindings,
    Initialization,
    ISuccessResponse,
    IResolvingPositionEventArgs,
    IGeolocationPosition,
    DistanceEvents,
} from 'coveo-search-ui';

import { IEndpointCaller, EndpointCallerWrapper } from '../../rest/EndpointCaller';

const GOOGLE_MAP_BASE_URL = 'https://www.googleapis.com/geolocation/v1/geolocate';

export interface IGoogleApiPositionProviderOptions {
    googleApiKey: string;
}

interface IGeolocationResponse {
    location: IGeolocationResponseLocation;
}

interface IGeolocationResponseLocation {
    lat: number;
    lng: number;
}

export class GoogleApiPositionProvider extends Component {
    public static ID = 'GoogleApiPositionProvider';
    public static options: IGoogleApiPositionProviderOptions = {
        googleApiKey: ComponentOptions.buildStringOption({
            required: true,
        }),
    };

    private endpointCaller: IEndpointCaller<IGeolocationResponse>;

    constructor(
        public element: HTMLElement,
        public options: IGoogleApiPositionProviderOptions,
        public bindings: IComponentBindings
    ) {
        super(element, GoogleApiPositionProvider.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, GoogleApiPositionProvider, options);
        this.endpointCaller = new EndpointCallerWrapper();

        this.bind.onRootElement(DistanceEvents.onResolvingPosition, this.onResolvingPosition);
        this.validateProperties();
    }

    private onResolvingPosition(args: IResolvingPositionEventArgs): void {
        if (this.canProvideDistance()) {
            args.providers.push({
                getPosition: () => this.getPosition(),
            });
        }
    }

    private canProvideDistance(): boolean {
        return !!this.options.googleApiKey;
    }

    private getPosition(): Promise<any> {
        return this.endpointCaller
            .sendPost(`${GOOGLE_MAP_BASE_URL}?key=${this.options.googleApiKey}`, {})
            .then((positionData: ISuccessResponse<IGeolocationResponse>) => {
                const location = positionData.data.location;
                return <IGeolocationPosition>{
                    latitude: location.lat,
                    longitude: location.lng,
                };
            });
    }

    private validateProperties(): void {
        if (!this.options.googleApiKey) {
            this.logger.error(
                `You must set the "googleApiKey" property for this component to work properly. It will be disabled.`
            );
        }
    }
}

Initialization.registerAutoCreateComponent(GoogleApiPositionProvider);
