import { Component, ComponentOptions, ExpressionBuilder, IComponentBindings, Initialization } from 'coveo-search-ui';

import { ISitecoreContextInitializationEventArgs, SitecoreContextEvents } from '../../events/ContextEvents';
import {
    IBuildingLocalSitecoreInstanceExpressionEventArgs,
    SitecoreExpressionEvents,
} from '../../events/ExpressionEvents';

export interface ICoveoForSitecoreLocalExpressionOptions {
    scFilterOnSitecoreContextDatabase: boolean;
    scFilterOnCurrentLanguage: boolean;
    scFilterOnLatestVersion: boolean;
}

export class CoveoForSitecoreLocalExpression extends Component {
    static ID = 'ForSitecoreLocalExpression';

    static options: ICoveoForSitecoreLocalExpressionOptions = {
        scFilterOnSitecoreContextDatabase: ComponentOptions.buildBooleanOption(),
        scFilterOnCurrentLanguage: ComponentOptions.buildBooleanOption(),
        scFilterOnLatestVersion: ComponentOptions.buildBooleanOption(),
    };

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreLocalExpressionOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreLocalExpression.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreLocalExpression, options);
        this.bind.onRootElement(SitecoreContextEvents.onSitecoreContextInitialization, this.onContextInitialization);
    }

    private onContextInitialization(context: ISitecoreContextInitializationEventArgs): void {
        this.bind.onRootElement(
            SitecoreExpressionEvents.onBuildingLocalSitecoreInstanceExpression,
            (builderFilterExpression: IBuildingLocalSitecoreInstanceExpressionEventArgs) =>
                this.onBuildingFilterExpression(builderFilterExpression, context)
        );
    }

    private onBuildingFilterExpression(
        buildingFilterExpressionEventArgs: IBuildingLocalSitecoreInstanceExpressionEventArgs,
        context: ISitecoreContextInitializationEventArgs
    ): void {
        if (!buildingFilterExpressionEventArgs.buildingQueryAlreadyProcessed) {
            buildingFilterExpressionEventArgs.buildingQueryAlreadyProcessed = true;
            const expression = new ExpressionBuilder();

            if (this.options.scFilterOnCurrentLanguage) {
                expression.addFieldExpression(`@${context.languageFieldName}`, '==', [context.currentLanguage]);
            }

            if (this.options.scFilterOnLatestVersion) {
                expression.addFieldExpression(`@${context.latestVersionFieldName}`, '==', ['1']);
            }

            if (this.options.scFilterOnSitecoreContextDatabase) {
                expression.addFieldExpression('@source', '==', [context.indexSourceName]);
            }

            if (!expression.isEmpty()) {
                buildingFilterExpressionEventArgs.expression.add(expression.build());
            }

            if (
                buildingFilterExpressionEventArgs.externalSources &&
                buildingFilterExpressionEventArgs.externalSources.length > 0
            ) {
                const sourceExpression = new ExpressionBuilder();

                sourceExpression.addFieldExpression('@source', '==', buildingFilterExpressionEventArgs.externalSources);
                if (!sourceExpression.isEmpty()) {
                    buildingFilterExpressionEventArgs.expression.add(sourceExpression.build(' OR '));
                }
            }
        }
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreLocalExpression);
