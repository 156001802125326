import { IRegisterableQueryNodeParser } from '../QueryNodeParser';
import { ICoveoFieldHandler } from '../../../../handlers/CoveoFieldsHandler';
import { IFieldNode } from '../QueryNodeTypes';
import { QueryUtils } from 'coveo-search-ui';

import * as dateformat from 'dateformat';

export class FieldNodeParser implements IRegisterableQueryNodeParser<IFieldNode> {
    private indexDateFormat = 'yyyy/mm/dd@HH:MM:ss';
    type: string = 'field';

    constructor(private fieldHandler: ICoveoFieldHandler) {}

    parse(node: IFieldNode): string {
        if (node.fieldValueType.name === 'string') {
            return this.parseString(
                this.fieldHandler.toCoveo(`@${node.fieldName}`),
                node.operator.name,
                node.fieldValues
            );
        } else {
            return QueryUtils.buildFieldExpression(
                this.fieldHandler.toCoveo(`@${node.fieldName}`),
                node.operator.name,
                this.parseFieldValuesFromType(node.fieldValueType.name, node.fieldValues)
            );
        }
    }

    parseFieldValuesFromType(type: string, values: any[]): string[] {
        switch (type) {
            case 'boolean':
                return values.map((value) => this.parseBoolean(value));
            case 'date':
                return values.map((value) => this.parseDate(value));
            case 'number':
                return values.map((value) => this.parseNumber(value));
            default:
                return values;
        }
    }

    parseBoolean(value: any): string {
        return !!value ? '1' : '0';
    }

    parseDate(value: any): string {
        const date = new Date(parseInt(value.replace('/Date(', '')));
        return dateformat(date, this.indexDateFormat);
    }

    parseNumber(value: any): string {
        return value.toString();
    }

    parseString(fieldName: any, operator: string, values: string[]): string {
        if (values.some((value) => value !== '')) {
            return QueryUtils.buildFieldExpression(
                `${fieldName}`,
                operator,
                values.filter((value) => value !== '')
            );
        } else {
            return this.parseEmptyField(fieldName, operator);
        }
    }

    parseEmptyField(fieldName: string, operator: string): string {
        switch (operator) {
            case '=':
            case '==':
                return fieldName;
            case '!=':
            case '<>':
                return `(NOT ${fieldName})`;
            default:
                throw `Operator "${operator}" for field "${fieldName}" requires a non-empty value.`;
        }
    }
}
