import {
    Component,
    ComponentOptions,
    IComponentBindings,
    Initialization,
    QueryEvents,
    IBuildingQueryEventArgs,
} from 'coveo-search-ui';

import { isTabCurrentlySelected } from '../../utils/TabUtils';
import {
    IQueryFilterNode,
    IQueryFilterNodeExpressionResolver,
    QueryFilterNodeExpressionResolverFactory,
    IQueryFilterNodeExpressionResolverFactory,
} from '../../resolvers/nodes/QueryFilterNodeExpressionResolver';

export interface ICoveoForSitecoreFilterExpressionOptions {
    scScopeToTab: string;
    scAdvancedFilter: string;
    scFilterScopeNode: IQueryFilterNode;
}

export class QueryFilterNodeOptions {
    public static parseQueryFilterNode(rawValue: string): IQueryFilterNode {
        return JSON.parse(this.unescapeAttributeValue(rawValue));
    }

    private static unescapeAttributeValue(value: string): string {
        return value
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&g‌​t;/g, '>')
            .replace(/&quot;/g, '"');
    }
}

export class CoveoForSitecoreFilterExpression extends Component {
    private queryFilterNodeResolver: IQueryFilterNodeExpressionResolver;

    static ID = 'ForSitecoreFilterExpression';

    static options: ICoveoForSitecoreFilterExpressionOptions = {
        scScopeToTab: ComponentOptions.buildStringOption(),
        scAdvancedFilter: ComponentOptions.buildStringOption(),
        scFilterScopeNode: ComponentOptions.buildCustomOption<IQueryFilterNode>((value) => {
            return QueryFilterNodeOptions.parseQueryFilterNode(value);
        }),
    };

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreFilterExpressionOptions,
        public bindings: IComponentBindings,
        private queryFilterNodeFactory: IQueryFilterNodeExpressionResolverFactory = new QueryFilterNodeExpressionResolverFactory()
    ) {
        super(element, CoveoForSitecoreFilterExpression.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreFilterExpression, options);
        this.bind.onRootElement(QueryEvents.buildingQuery, this.onBuildingQuery);
    }

    private onBuildingQuery(buildingQuery: IBuildingQueryEventArgs): void {
        if (this.shouldAddFilterExpression()) {
            if (!!this.options.scAdvancedFilter) {
                buildingQuery.queryBuilder.advancedExpression.add(this.options.scAdvancedFilter.trim());
            }
            if (!!this.options.scFilterScopeNode) {
                try {
                    const resolver = this.getQueryFilterNodeExpressionResolver();
                    const resultingBuilder = resolver.parseQueryFilterNode(this.options.scFilterScopeNode);
                    buildingQuery.queryBuilder.advancedExpression.fromExpressionBuilder(
                        resultingBuilder.advancedExpression
                    );
                } catch (exception) {
                    this.logger.error('Could not parse the query filter node.', exception);
                }
            }
        }
    }

    private getQueryFilterNodeExpressionResolver(): IQueryFilterNodeExpressionResolver {
        return (
            this.queryFilterNodeResolver ||
            (this.queryFilterNodeResolver = this.queryFilterNodeFactory.createQueryFilterNodeExpression(this.logger))
        );
    }

    private shouldAddFilterExpression(): boolean {
        return isTabCurrentlySelected(this.options.scScopeToTab, this.searchInterface);
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreFilterExpression);
