import { IQueryNode } from './queryNodes/QueryNodeTypes';
import { IQueryNodeParser } from './queryNodes/QueryNodeParser';
import { QueryNodeTreeParserFactory } from './queryNodes/QueryNodeTreeParserFactory';
import { ICoveoFieldHandler } from '../../handlers/CoveoFieldsHandler';
import { Context } from '../../base/Context';

import { QueryBuilder, IRankingFunction, Logger } from 'coveo-search-ui';

export interface IQueryRankingNode {
    rankingExpressions: IRankingExpressionNode[];
    rankingFunctions: IRankingFunction[];
    textualRankingExpressions: ITextualRankingExpression[];
}

export interface IRankingExpressionNode {
    expression: IQueryNode;
    modifier: number;
}

export interface ITextualRankingExpression {
    expression: string;
}

/*
    Matches "@[x]"" and return x as matching group.
    Examples:
        * @[blublu] gives "blublu".
        * @[[blublu]] gives "[blublu]"
        * @[blublu]] gives "blublu]"
*/
const rankingExpressionFieldsRegex: RegExp = /@\[(.*?\]*)\]/g.compile();

export class QueryRankingNodeExpressionResolver implements IQueryRankingNodeExpressionResolver {
    constructor(private parser: IQueryNodeParser, private fieldsHandler: ICoveoFieldHandler) {}

    parseQueryRankingNode(queryRankingNode: IQueryRankingNode): QueryBuilder {
        const queryBuilder = new QueryBuilder();
        queryRankingNode.rankingExpressions.map((ranking) => {
            const parsedExpression = this.parser.parse(ranking.expression);
            if (parsedExpression !== '') {
                queryBuilder.advancedExpression.add(
                    `$qre(expression:'${parsedExpression}', modifier:${ranking.modifier})`
                );
            }
        });
        queryRankingNode.rankingFunctions.forEach((rankingFunction) => {
            rankingFunction.expression = this.translateRankingExpressionFieldNames(rankingFunction.expression);
            queryBuilder.rankingFunctions.push(rankingFunction);
        });
        queryRankingNode.textualRankingExpressions
            .filter((rankingExpression) => rankingExpression.expression !== '')
            .forEach((rankingExpression) => {
                queryBuilder.advancedExpression.add(rankingExpression.expression);
            });
        return queryBuilder;
    }

    private translateRankingExpressionFieldNames(expression: string): string {
        expression.replace(rankingExpressionFieldsRegex, (matchingExpression, matchingGroup) => {
            return this.fieldsHandler.toCoveo(matchingGroup);
        });
        return expression;
    }
}

export interface IQueryRankingNodeExpressionResolver {
    parseQueryRankingNode(queryRankingNode: IQueryRankingNode): QueryBuilder;
}

export class QueryRankingNodeExpressionResolverFactory {
    createQueryFilterNodeExpression(logger: Logger): IQueryRankingNodeExpressionResolver {
        const parserFactory = new QueryNodeTreeParserFactory();
        const parser = parserFactory.createParser(logger);
        return new QueryRankingNodeExpressionResolver(parser, Context.fields);
    }
}
