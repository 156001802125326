import { IRegisterableQueryNodeParser, IQueryNodeParser } from '../QueryNodeParser';
import { IOrNode } from '../QueryNodeTypes';

export class OrNodeParser implements IRegisterableQueryNodeParser<IOrNode> {
    type: string = 'or';

    constructor(private nodeParser: IQueryNodeParser) {}

    parse(node: IOrNode): string {
        return `(${this.nodeParser.parse(node.left)} OR ${this.nodeParser.parse(node.right)})`;
    }
}
