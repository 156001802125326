import { IEndpointCaller, EndpointCallerWrapper } from '../../rest/EndpointCaller';
import { ISitecoreContextInitializationEventArgs, SitecoreContextEvents } from '../../events/ContextEvents';

import {
    AnalyticsEvents,
    IAnalyticsSearchEventsArgs,
    IAnalyticsDocumentViewEventArgs,
    IAnalyticsCustomEventArgs,
    Component,
    IComponentBindings,
    ComponentOptions,
    IStringMap,
    Initialization,
} from 'coveo-search-ui';

export interface ISendAnalyticsToSitecoreOptions {
    scAnalyticsEndpointUri: string;
}

export class SendAnalyticsToSitecore extends Component {
    static ID = 'SendAnalyticsToSitecore';

    static options: ISendAnalyticsToSitecoreOptions = {
        scAnalyticsEndpointUri: ComponentOptions.buildStringOption({
            required: true,
            postProcessing: (value) => {
                return typeof value !== 'undefined' && value[value.length - 1] === '/'
                    ? value.slice(0, value.length - 1)
                    : value;
            },
        }),
    };

    private endpointCaller: IEndpointCaller<any>;

    constructor(
        public element: HTMLElement,
        public options: ISendAnalyticsToSitecoreOptions,
        public bindings: IComponentBindings
    ) {
        super(element, SendAnalyticsToSitecore.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, SendAnalyticsToSitecore, options);

        this.endpointCaller = new EndpointCallerWrapper();
        this.bind.onRootElement<ISitecoreContextInitializationEventArgs>(
            SitecoreContextEvents.onSitecoreContextInitialization,
            this.onSitecoreContextInitialization
        );
    }

    private onSitecoreContextInitialization(sitecoreContext: ISitecoreContextInitializationEventArgs): void {
        if (sitecoreContext.analyticsEnabled) {
            this.bind.onRootElement(AnalyticsEvents.searchEvent, (args: IAnalyticsSearchEventsArgs) => {
                this.postAnalytics('searches', 'searchEvents', args.searchEvents, sitecoreContext);
            });
            this.bind.onRootElement(AnalyticsEvents.documentViewEvent, (args: IAnalyticsDocumentViewEventArgs) => {
                this.postAnalytics('documentViews', 'documentViewEvents', args.documentViewEvent, sitecoreContext);
            });
            this.bind.onRootElement(AnalyticsEvents.customEvent, (args: IAnalyticsCustomEventArgs) => {
                this.postAnalytics('custom', 'customEvents', args.customEvent, sitecoreContext);
            });
        }
    }

    private postAnalytics(
        eventAction: string,
        eventType: string,
        eventData: any,
        sitecoreContext: ISitecoreContextInitializationEventArgs
    ): void {
        let parsedEventData: Array<any> = [];
        if (typeof eventData !== 'undefined') {
            parsedEventData = parsedEventData.concat(eventData);
        }
        parsedEventData
            .filter((data: any) => {
                return typeof data !== 'undefined';
            })
            .forEach((data: any) => {
                data.sitecoreItemId = sitecoreContext.sitecoreItemId;
            });

        const postData: IStringMap<string> = {};
        postData[eventType] = JSON.stringify(parsedEventData);

        this.endpointCaller.sendPost(`${this.options.scAnalyticsEndpointUri}/${eventAction}`, postData);
    }
}

Initialization.registerAutoCreateComponent(SendAnalyticsToSitecore);
