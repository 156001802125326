import {
    Component,
    ComponentOptions,
    IComponentBindings,
    Initialization,
    IResolvingPositionEventArgs,
    IGeolocationPosition,
    DistanceEvents,
} from 'coveo-search-ui';

export interface INavigatorPositionProviderOptions {}

export class NavigatorPositionProvider extends Component {
    public static ID = 'NavigatorPositionProvider';
    public static options: INavigatorPositionProviderOptions = {};

    constructor(
        public element: HTMLElement,
        public options: INavigatorPositionProviderOptions,
        public bindings: IComponentBindings
    ) {
        super(element, NavigatorPositionProvider.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, NavigatorPositionProvider, options);

        this.bind.onRootElement(DistanceEvents.onResolvingPosition, this.onResolvingPosition);
    }

    private onResolvingPosition(args: IResolvingPositionEventArgs): void {
        args.providers.push({
            getPosition: () => this.getPosition(),
        });
    }

    private getPosition(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve(<IGeolocationPosition>{
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}

Initialization.registerAutoCreateComponent(NavigatorPositionProvider);
