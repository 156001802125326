import {
    Component,
    ComponentOptions,
    IComponentBindings,
    Initialization,
    IBuildingQueryEventArgs,
    QueryEvents,
    ExpressionBuilder,
} from 'coveo-search-ui';

import { ISitecoreContextInitializationEventArgs, SitecoreContextEvents } from '../../events/ContextEvents';
import {
    IBuildingExternalContentEventArgs,
    IBuildingLocalSitecoreInstanceExpressionEventArgs,
    SitecoreExpressionEvents,
} from '../../events/ExpressionEvents';

export interface ICoveoForSitecoreExpressionOptions {}

const IS_COVEO_FOR_SITECORE_EXPRESSIONS_EVENT_REGISTERED = 'coveoForSitecoreEventRegistered';

export class CoveoForSitecoreExpressions extends Component {
    static ID = 'ForSitecoreExpressions';

    static options: ICoveoForSitecoreExpressionOptions = {};

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreExpressionOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreExpressions.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreExpressions, options);
        this.bind.onRootElement(
            SitecoreContextEvents.onSitecoreContextInitialization,
            this.afterComponentsInitialization
        );
    }

    private afterComponentsInitialization(contextArgs: ISitecoreContextInitializationEventArgs): void {
        if (!this.root.dataset[IS_COVEO_FOR_SITECORE_EXPRESSIONS_EVENT_REGISTERED]) {
            this.root.dataset[IS_COVEO_FOR_SITECORE_EXPRESSIONS_EVENT_REGISTERED] = 'true';
            this.bind.onRootElement(QueryEvents.buildingQuery, (buildingQueryArgs: IBuildingQueryEventArgs) => {
                this.onBuildingQuery(buildingQueryArgs, contextArgs);
            });
        }
    }

    private onBuildingQuery(
        buildingQueryArgs: IBuildingQueryEventArgs,
        contextArgs: ISitecoreContextInitializationEventArgs
    ): void {
        const externalContentArgs: IBuildingExternalContentEventArgs = this.triggerBuildingSitecoreExternalContent();
        const localSitecoreInstanceArgs: IBuildingLocalSitecoreInstanceExpressionEventArgs = this.triggerBuildingLocalSitecoreInstanceEvent(
            externalContentArgs.sources
        );

        if (!localSitecoreInstanceArgs.expression.isEmpty()) {
            buildingQueryArgs.queryBuilder.constantExpression.add(localSitecoreInstanceArgs.expression.build(' OR '));
        }
    }

    private triggerBuildingSitecoreExternalContent(): IBuildingExternalContentEventArgs {
        const externalContentArgs: IBuildingExternalContentEventArgs = {
            sources: [],
        };

        this.bind.trigger(
            this.element,
            SitecoreExpressionEvents.onBuildingSitecoreExternalContent,
            externalContentArgs
        );

        return {
            sources: this.filterUnique(externalContentArgs.sources),
        };
    }

    private triggerBuildingLocalSitecoreInstanceEvent(
        externalSources: string[]
    ): IBuildingLocalSitecoreInstanceExpressionEventArgs {
        const localSitecoreInstanceArgs: IBuildingLocalSitecoreInstanceExpressionEventArgs = {
            expression: new ExpressionBuilder(),
            externalSources: externalSources,
            buildingQueryAlreadyProcessed: false,
        };

        this.bind.trigger(
            this.element,
            SitecoreExpressionEvents.onBuildingLocalSitecoreInstanceExpression,
            localSitecoreInstanceArgs
        );

        return localSitecoreInstanceArgs;
    }

    private filterUnique<T>(array: T[]): T[] {
        return array.filter((value, index, self) => self.indexOf(value) === index);
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreExpressions);
