import {
    Component,
    ComponentOptions,
    IComponentBindings,
    Initialization,
    QueryEvents,
    QueryBuilder,
    IBuildingQueryEventArgs,
} from 'coveo-search-ui';

import { isTabCurrentlySelected } from '../../utils/TabUtils';
import {
    IQueryRankingNode,
    IQueryRankingNodeExpressionResolver,
    QueryRankingNodeExpressionResolverFactory,
} from '../../resolvers/nodes/QueryRankingNodeExpressionResolver';

export interface ICoveoForSitecoreRankingExpressionOptions {
    scScopeToTab: string;
    scRankingExpression: string;
    scRankingScopeNode: IQueryRankingNode;
}

export class CoveoForSitecoreRankingExpression extends Component {
    private nodeResolver: IQueryRankingNodeExpressionResolver;

    static ID = 'ForSitecoreRankingExpression';

    static options: ICoveoForSitecoreRankingExpressionOptions = {
        scScopeToTab: ComponentOptions.buildStringOption(),
        scRankingExpression: ComponentOptions.buildStringOption(),
        scRankingScopeNode: ComponentOptions.buildCustomOption<IQueryRankingNode>((value) => {
            return JSON.parse(value);
        }),
    };

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreRankingExpressionOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreRankingExpression.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreRankingExpression, options);
        this.bind.onRootElement(QueryEvents.buildingQuery, this.onBuildingQuery);
    }

    private onBuildingQuery(buildingQuery: IBuildingQueryEventArgs): void {
        if (this.shouldAddRankingExpression()) {
            if (!!this.options.scRankingExpression) {
                buildingQuery.queryBuilder.advancedExpression.add(this.options.scRankingExpression.trim());
            }
            if (!!this.options.scRankingScopeNode) {
                const resolver = this.getNodeResolver();
                const builder = resolver.parseQueryRankingNode(this.options.scRankingScopeNode);
                builder.rankingFunctions.forEach((rankingFunction) =>
                    buildingQuery.queryBuilder.rankingFunctions.push(rankingFunction)
                );
                this.copyQueryBuilderAttributes(builder, buildingQuery.queryBuilder);
            }
        }
    }

    private copyQueryBuilderAttributes(from: QueryBuilder, to: QueryBuilder): void {
        from.rankingFunctions.forEach((rankingFunction) => to.rankingFunctions.push(rankingFunction));
        to.advancedExpression.fromExpressionBuilder(from.advancedExpression);
    }

    private getNodeResolver(): IQueryRankingNodeExpressionResolver {
        return (
            this.nodeResolver ||
            (this.nodeResolver = new QueryRankingNodeExpressionResolverFactory().createQueryFilterNodeExpression(
                this.logger
            ))
        );
    }

    private shouldAddRankingExpression(): boolean {
        return isTabCurrentlySelected(this.options.scScopeToTab, this.searchInterface);
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreRankingExpression);
