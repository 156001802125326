import {
    Component,
    ComponentOptions,
    IComponentBindings,
    Initialization,
    IResolvingPositionEventArgs,
    IGeolocationPosition,
    DistanceEvents,
} from 'coveo-search-ui';

export interface IStaticPositionProviderOptions {
    longitude: number;
    latitude: number;
}

export class StaticPositionProvider extends Component {
    public static ID = 'StaticPositionProvider';
    public static options: IStaticPositionProviderOptions = {
        latitude: ComponentOptions.buildNumberOption({
            required: true,
            float: true,
        }),
        longitude: ComponentOptions.buildNumberOption({
            required: true,
            float: true,
        }),
    };

    constructor(
        public element: HTMLElement,
        public options: IStaticPositionProviderOptions,
        public bindings: IComponentBindings
    ) {
        super(element, StaticPositionProvider.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, StaticPositionProvider, options);

        this.bind.onRootElement(DistanceEvents.onResolvingPosition, this.onResolvingPosition);
    }

    private onResolvingPosition(args: IResolvingPositionEventArgs): void {
        if (this.canGetPosition()) {
            args.providers.push({
                getPosition: () => this.getPosition(),
            });
        }
    }

    private canGetPosition(): boolean {
        return !!this.options.latitude && !!this.options.longitude;
    }

    private getPosition(): Promise<any> {
        return Promise.resolve(<IGeolocationPosition>{
            longitude: this.options.longitude,
            latitude: this.options.latitude,
        });
    }
}

Initialization.registerAutoCreateComponent(StaticPositionProvider);
