import { Component, ComponentOptions, IComponentBindings, Initialization } from 'coveo-search-ui';
import { SitecoreContextEvents, ISitecoreContextInitializationEventArgs } from '../../events/ContextEvents';

export interface IValidateAnalyticsIncludedOptions {
    componentName: string;
}

export class ValidateAnalyticsIncluded extends Component {
    static ID = 'ValidateAnalyticsIncluded';

    static options: IValidateAnalyticsIncludedOptions = {
        componentName: ComponentOptions.buildStringOption({
            defaultValue: '',
            attrName: 'data-component-name',
        }),
    };

    constructor(
        public element: HTMLElement,
        public options: IValidateAnalyticsIncludedOptions,
        public bindings: IComponentBindings
    ) {
        super(element, ValidateAnalyticsIncluded.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, ValidateAnalyticsIncluded, options);

        this.bind.onRootElement(
            SitecoreContextEvents.onSitecoreContextInitialization,
            this.onSitecoreContextInitialization
        );
    }

    private onSitecoreContextInitialization(sitecoreContextArgs: ISitecoreContextInitializationEventArgs): void {
        if (sitecoreContextArgs.shouldHaveAnalyticsComponent && this.searchInterfaceHasNoBoundAnalyticsComponent()) {
            if (sitecoreContextArgs.isInExperienceEditor) {
                let errorMessage = getErrorMessage(this.options.componentName || 'Search Interface');

                if (!this.checkIfErrorInErrorMessage(errorMessage)) {
                    console.warn(errorMessage);
                    this.element.appendChild(this.createErrorMessageElement(errorMessage));
                }
            }
        }
    }

    private checkIfErrorInErrorMessage(errorMessage: string): boolean {
        for (let index = 0; index < this.element.children.length; index++) {
            const element = this.element.children[index];
            if (element.textContent.indexOf(errorMessage, 0) !== -1) {
                return true;
            }
        }

        return false;
    }

    private searchInterfaceHasNoBoundAnalyticsComponent(): boolean {
        return (
            this.searchInterface.getComponents('Analytics').length === 0 &&
            this.searchInterface.getComponents('ForSitecoreAnalytics').length === 0
        );
    }

    private createErrorMessageElement(errorMessage: string): HTMLElement {
        const errorSpan = document.createElement('span');

        errorSpan.classList.add('errorMessage');
        errorSpan.textContent = errorMessage;

        return errorSpan;
    }
}

function getErrorMessage(component: string): string {
    return `The Coveo Analytics are not enabled for this ${component}. Insert a Coveo For Sitecore Analytics component to record Coveo Usage Analytics data.`;
}

Initialization.registerAutoCreateComponent(ValidateAnalyticsIncluded);
