import {
    Component,
    ComponentOptions,
    IBuildingQuerySuggestArgs,
    IComponentBindings,
    IDoneBuildingQueryEventArgs,
    Initialization,
    OmniboxEvents,
    QueryEvents,
} from 'coveo-search-ui';

export interface ICoveoForSitecoreBindWithUserContextOptions {}

export class CoveoForSitecoreBindWithUserContext extends Component {
    static ID = 'ForSitecoreBindWithUserContext';

    static options: ICoveoForSitecoreBindWithUserContextOptions = {};

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreBindWithUserContextOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreBindWithUserContext.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreBindWithUserContext, options);
        this.bind.onRootElement(QueryEvents.doneBuildingQuery, this.doneBuildingQuery);
        this.bind.onRootElement(OmniboxEvents.buildingQuerySuggest, this.buildingQuerySuggest);
    }

    private doneBuildingQuery(args: IDoneBuildingQueryEventArgs): void {
        const context = window['CoveoForSitecoreUserContext'];
        if (!!context && !!context.handler) {
            args.queryBuilder.addContext(context.handler.getContext());
        }
    }

    private buildingQuerySuggest(args: IBuildingQuerySuggestArgs): void {
        const context = window['CoveoForSitecoreUserContext'];
        if (!!context && !!context.handler) {
            args.payload.context = context.handler.getContext();
        }
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreBindWithUserContext);
