import { AndNodeParser } from './parsers/AndNodeParser';
import { FalseNodeParser } from './parsers/FalseNodeParser';
import { FieldNodeParser } from './parsers/FieldNodeParser';
import { NotNodeParser } from './parsers/NotNodeParser';
import { OrNodeParser } from './parsers/OrNodeParser';
import { TrueNodeParser } from './parsers/TrueNodeParser';
import { IQueryNodeTreeParser, QueryNodeTreeParser } from './QueryNodeTreeParser';
import { Context } from '../../../base/Context';

import { Logger } from 'coveo-search-ui';

export class QueryNodeTreeParserFactory implements IQueryNodeTreeParserFactory {
    createParser(logger: Logger): IQueryNodeTreeParser {
        const parser: IQueryNodeTreeParser = new QueryNodeTreeParser(logger);

        parser.registerParser(new AndNodeParser(parser));
        parser.registerParser(new FalseNodeParser());
        parser.registerParser(new FieldNodeParser(Context.fields));
        parser.registerParser(new NotNodeParser(parser));
        parser.registerParser(new OrNodeParser(parser));
        parser.registerParser(new TrueNodeParser());

        return parser;
    }
}

export interface IQueryNodeTreeParserFactory {
    createParser(logger: Logger): IQueryNodeTreeParser;
}
