import { IQueryNode } from './queryNodes/QueryNodeTypes';
import { IQueryNodeParser } from './queryNodes/QueryNodeParser';
import { QueryNodeTreeParserFactory } from './queryNodes/QueryNodeTreeParserFactory';

import { QueryBuilder, Logger, Assert } from 'coveo-search-ui';

export interface IQueryFilterNode {
    advanced: IQueryNode;
}

export class QueryFilterNodeExpressionResolver implements IQueryFilterNodeExpressionResolver {
    constructor(private parser: IQueryNodeParser) {}

    parseQueryFilterNode(queryFilterNode: IQueryFilterNode): QueryBuilder {
        Assert.isNotNull(queryFilterNode);
        const queryBuilder = new QueryBuilder();
        queryBuilder.advancedExpression.add(this.parser.parse(queryFilterNode.advanced));
        return queryBuilder;
    }
}

export interface IQueryFilterNodeExpressionResolver {
    parseQueryFilterNode(queryFilterNode: IQueryFilterNode): QueryBuilder;
}

export interface IQueryFilterNodeExpressionResolverFactory {
    createQueryFilterNodeExpression(logger: Logger): IQueryFilterNodeExpressionResolver;
}

export class QueryFilterNodeExpressionResolverFactory implements IQueryFilterNodeExpressionResolverFactory {
    createQueryFilterNodeExpression(logger: Logger): IQueryFilterNodeExpressionResolver {
        const parserFactory = new QueryNodeTreeParserFactory();
        const parser = parserFactory.createParser(logger);
        return new QueryFilterNodeExpressionResolver(parser);
    }
}
