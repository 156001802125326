import { IStringMap, IEndpointCallParameters, EndpointCaller, ISuccessResponse } from 'coveo-search-ui';

export class EndpointCallerWrapper<T> implements IEndpointCaller<T> {
    private endpointCaller: EndpointCaller;

    constructor(endpointCaller?: EndpointCaller) {
        this.endpointCaller = endpointCaller || new EndpointCaller();
    }

    public sendPost(url: string, postData: IStringMap<string>): Promise<ISuccessResponse<T>> {
        const callOptions: IEndpointCallParameters = {
            errorsAsSuccess: false,
            method: 'POST',
            queryString: [],
            requestData: postData,
            url: url,
            responseType: 'text',
            requestDataType: 'application/json',
        };
        return this.endpointCaller.call(callOptions);
    }
}

export interface IEndpointCaller<T> {
    sendPost(url: string, postData: IStringMap<string>): Promise<ISuccessResponse<T>>;
}
