import { Component, ComponentOptions, IComponentBindings, Initialization } from 'coveo-search-ui';

import { isTabCurrentlySelected } from '../../utils/TabUtils';
import { IBuildingExternalContentEventArgs, SitecoreExpressionEvents } from '../../events/ExpressionEvents';

export interface ICoveoForSitecoreExternalContentOptions {
    scSources: string[];
    scScopeToTab: string;
}

export class CoveoForSitecoreExternalContent extends Component {
    static ID = 'ForSitecoreExternalContent';

    static options: ICoveoForSitecoreExternalContentOptions = {
        scSources: ComponentOptions.buildListOption<string>(),
        scScopeToTab: ComponentOptions.buildStringOption(),
    };

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreExternalContentOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreExternalContent.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreExternalContent, options);
        this.bind.onRootElement(
            SitecoreExpressionEvents.onBuildingSitecoreExternalContent,
            this.onBuildingSitecoreExternalContent
        );
    }

    private onBuildingSitecoreExternalContent(
        buildingExternalContentEventArgs: IBuildingExternalContentEventArgs
    ): void {
        if (this.shouldAddExternalContent()) {
            buildingExternalContentEventArgs.sources.push(...this.options.scSources);
        }
    }

    private shouldAddExternalContent(): boolean {
        return isTabCurrentlySelected(this.options.scScopeToTab, this.searchInterface);
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreExternalContent);
