import { IRegisterableQueryNodeParser, IQueryNodeParser } from '../QueryNodeParser';
import { IAndNode } from '../QueryNodeTypes';
export class AndNodeParser implements IRegisterableQueryNodeParser<IAndNode> {
    type: string = 'and';

    constructor(private nodeParser: IQueryNodeParser) {}

    parse(node: IAndNode): string {
        return `(${this.nodeParser.parse(node.left)} ${this.nodeParser.parse(node.right)})`;
    }
}
