import { Component, ComponentOptions, Initialization, InitializationEvents, IComponentBindings } from 'coveo-search-ui';

const searchHubAttribute = 'searchHub';

export interface ICoveoForSitecoreAnalyticsOptions {
    scSearchHub: string;
}

export class CoveoForSitecoreConfigureSearchHub extends Component {
    static ID = 'ForSitecoreConfigureSearchHub';

    static options: ICoveoForSitecoreAnalyticsOptions = {
        scSearchHub: ComponentOptions.buildStringOption(),
    };

    constructor(
        public element: HTMLElement,
        public options: ICoveoForSitecoreAnalyticsOptions,
        public bindings: IComponentBindings
    ) {
        super(element, CoveoForSitecoreConfigureSearchHub.ID, bindings);

        this.options = ComponentOptions.initComponentOptions(element, CoveoForSitecoreConfigureSearchHub, options);

        if (!!this.options.scSearchHub) {
            this.bind.oneRootElement(
                InitializationEvents.afterComponentsInitialization,
                this.onAfterComponentsInitialization
            );
        }
    }

    private onAfterComponentsInitialization(): void {
        const currentSearchHub = this.componentOptionsModel.get(searchHubAttribute);
        if (!currentSearchHub) {
            this.componentOptionsModel.set(searchHubAttribute, this.options.scSearchHub);
        }
    }
}

Initialization.registerAutoCreateComponent(CoveoForSitecoreConfigureSearchHub);
